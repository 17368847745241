import React from 'react';
import Button from '@material-ui/core/Button';
import { AppBar, Container, Toolbar, Typography, Box,  Paper,
   Grid} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import bacGroundChildren from './children_backGround.svg';
import childrenMain from './ChildrenMain.svg';



const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
    marginTop: theme.spacing(4)
	},
  menuButton:{
    marginRight: theme.spacing(40)
  },
	title: {
		flexGrow: 1,
    position: "relative",
    padding: theme.spacing(0),
    marginTop: theme.spacing(0),
    color: "rgba(0,0,0)",
	},
  titleUp_1: {
		flexGrow: 1,
    position: "relative",
    color: "rgba(0,0,0)",
    marginLeft: theme.spacing(6),
	},
  titleUp_2: {
		flexGrow: 1,
    position: "relative",
    color: "rgba(0,0,0)",
    marginLeft: theme.spacing(7),
	},
  mainFeaturesPost:{
    position: "relative",
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),

    backgroundSize: "cover",
    backgroundRepeat: "no-repete",
    backgroundPosition: "center"
  },
  mainPostContent:{
    position: "avsolute",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(6),
    
    width: "500px",
    height: "350px",

    backgroundSize: "cover",
  
    backgroundPosition: "center"
  },
  overlay:{
    position: "avsolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundOverlay: "rgba(0,0,0,.9)"
  },
  mainContent:{
    marginTop: theme.spacing(6)
  },
  mainFeaturesPostContent: {
    position: "relative",
    padding: theme.spacing(18),
    marginTop: theme.spacing(15),
    color: "rgba(15,30,115)",
  },
  mainFeaturesPostContentLabl: {
    position: "center",
    padding: theme.spacing(0),
    marginBottom:0,
    marginTop:0,
    width: "80px",
    height: "80px",

    backgroundSize: "contain",
    backgroundRepeat: "no-repete",
    backgroundPosition: "center",
    outline:"none"
  },
  cardGrid:{
    marginBottom: theme.spacing(6)
  },
  TypographyMain:{
    position: "avsolute",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(6),
  },
  TypographyMainTwo:{
    position: "avsolute",
    marginTop: theme.spacing(3),
  },
  TypographyMainFrie:{
    position: "avsolute",
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(16),
    align: "left"
  },
  mainFoter:{
    marginTop: theme.spacing(8),
    paddingBottom: "30px"
  },
  overCards:{
    position: "center",
    marginTop: theme.spacing(8),

    backgroundSize: "cover",
    backgroundRepeat: "no-repete",
    backgroundPosition: "center",

  },
  mainFoterText:{
    marginTop: theme.spacing(4)
  },
  appBar:{
    backgroundSize: "100%",
    backgroundRepeat: "no-repete",
    backgroundPosition: "center",
    backgroundColor: "rgba(116, 222, 221)",
    
  },
  LogoMain:{
    position: "avsolute",
    width: "80px",
    height: "80px",
    
    backgroundSize: "cover",
    backgroundRepeat: "no-repete",
    backgroundPosition: "center",
  },
  toolDownbar:{
    marginTop: "1px",
    paddingTop:"20px"
  },
  LablVk:{
    height:"35px"
  }
 
}))

function App() {
	const classes = useStyles();

	return (
    <>
		<AppBar position="fixed" className={classes.appBar}>
			<Container fixed >
				<Toolbar >
            <Paper className={classes.mainFeaturesPostContentLabl}
            elevation={0}>
              <Grid >
                <img src={ require('./Leble_2.png') } alt="" 
                className={classes.LogoMain}/>
              </Grid> 
              <Container fixed >
                <div className={classes.overlay}/>
                <Grid container>
                </Grid>
              </Container>
            </Paper>
					<Typography 
          variant="h6" className={classes.title} gutterBottom >
            <Typography variant="h6"  className={classes.titleUp_1}>
              Центр
            </Typography>
            <Typography variant="h6"  >
              Восстановления
            </Typography>
            <Typography variant="h6"  className={classes.titleUp_2}>
              Речи
            </Typography>
          </Typography>
					<Box mr={3} className={classes.menuButton}>
						<Button color="black" variant="outlined:hover" 
            style={{borderWidth:1,
            borderColor:'black',
            }}
            >Главная</Button>
            <Button color="black"  variant="outlined:hover" >О нас</Button>
            <Button color="black" variant="outlined:hover" >Контакты</Button>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>

    <main>
      <Paper className={classes.mainFeaturesPost}
      style={{backgroundImage: "url(" + bacGroundChildren + ")"}}>
          <Container fixed>
            <div className={classes.overlay}/>
            <Grid container>
              <Grid item md={6}>
                
                <div className={classes.mainFeaturesPostContent}>
                  <Typography 
                  component="h1"
                  variant="h3" 
                  gutterBottom
                  >
                    Ваш ребёнок будет говорить!
                  </Typography>
                  <Typography 
                  variant="h4" 
                  paragraph
                  >
                    Наша миссия:
                  </Typography>
                  <Typography 
                  variant="h6" 
                  paragraph
                  >                
                    Оказание помощи, индивидуальный подход,
                    с использованием методики Тарасенко В.И.
                  </Typography>

                </div>
              </Grid>
            </Grid>
          </Container>
      </Paper>

      <div className={classes.mainContent}>
        <Container maxWidth="md">
          <Typography variant="h2" align="center" 
          color="textPrimary" paragraph>
            Почему люди выбирают нас</Typography>
          <Typography variant="h5" align="center" 
          color="textSecondary" gutterBottom>
           1. Мы применяем множество методов  с Высокой эффективностью 🔬</Typography>
          <Typography variant="h5" align="center" 
          color="textSecondary" gutterBottom>
           2. Мы работаем по всему миру</Typography>
          <Typography variant="h5" align="center" 
          color="textSecondary" gutterBottom>
           3. У нас есть тысячи положительных отзывов</Typography>
          <Typography variant="h5" align="center" 
          color="textSecondary" gutterBottom>
           4. При необходимости  проводим дистаннционную работу🧬</Typography>
           <Typography variant="h5" align="center" 
          color="textSecondary" gutterBottom>
           5. Специалисты высокого уровня 👩‍⚕️</Typography>
           <Typography variant="h5" align="center" 
          color="textSecondary" gutterBottom>
           6. Опыт применения методики более 10 лет 📆</Typography>
           <Typography variant="h5" align="center" 
          color="textSecondary" gutterBottom>
           7. Мы одни из немногих кто имеет результат у детей старше 7 лет</Typography>
        </Container>

        <div >
          <Container >
            <Grid> 
              <Grid container spacing={0} align="center">
                <Grid item sm={6}>
                  <Paper className={classes.mainPostContent}
                    style={{backgroundImage: "url(" + childrenMain + ")"}}>
                  </Paper>
                </Grid>
                <Grid item sm={6}>
                  <Container>
                    <Typography className={classes.TypographyMain}
                    align="center" gutterBottom>
                      ВОССТАНОВЛЕНИЕ 
                    </Typography>
                    <Typography 
                    align="center" gutterBottom>
                      РЕЧИ, МОТОРИКИ,ЛЮБЫХ ЗАДЕРЖЕК РАЗВИТИЯ ПО МЕТОДИКЕ
                    </Typography>
                    <Typography 
                    align="center" gutterBottom>
                      Тарасенко В.И.
                    </Typography>
                    <Typography 
                    align="center" gutterBottom
                    variant='h5'>
                      ЧТО МЫ ДЕЛАЕМ ?
                    </Typography>
                    <Typography 
                    align="center" gutterBottom>
                      1. Ставим диагноз (или диагнозы).
                    </Typography>
                    <Typography 
                    align="center" gutterBottom>
                      2. Разрабатываем стратегию максимальной коррекции нарушений – исследования, терапии, упражнения, лекарства, методики коррекции и восстановления.
                    </Typography>
                    <Typography 
                    align="center" gutterBottom>
                      3. Обучаем маму как общаться и заниматься с ее малышом.
                    </Typography>
                    <Typography 
                    align="center" gutterBottom>
                      4. Консультируем ребенка и маму до садика или школы.
                    </Typography>
                  </Container>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
        
        <div>
          <Container className={classes.TypographyMainTwo}>
            <Grid>
              <Typography align="center" variant="h5">
                Восстановление речи, моторики, любых задержек развития 
              </Typography>
              <Typography align="center" variant="h5">
                осуществляетсья с помошью методов:
              </Typography>
            </Grid>
          </Container>

          <Container className={classes.TypographyMainFrie}>
            <Grid>
            <Typography>
                1. Высокочастотная терапия
              </Typography>
              <Typography >
              2. Микрополяризация
              </Typography>
              <Typography>
              3. Оксигенотерапия
              </Typography>
              <Typography  >
              4. Логопед INTELLECT
              </Typography>
              <Typography>
              5. Шкокльник INTELLECT
              </Typography>
              <Typography>
              6. SpeechLeader
              </Typography>
              <Typography>
              7. Терапия для беременных
              </Typography>
              <Typography>
              8.  Иностранный как родной
              </Typography>
              <Typography>
              9.  Визуальная волновая терапия
              </Typography>
              <Typography>
              10.  Логомассаж
              </Typography>
              <Typography>
              11.  Виброфоны
              </Typography>
            </Grid>
          </Container>
          <Container className={classes.TypographyMainFrie}>
            <Grid>
            <Typography>
            Программа Высокочастотной Терапии восстанавливает: 
              </Typography>
              <Typography >
              ▪️Все речевые расстройства, в частности:
              </Typography>
              <Typography>
              ▪️СЕНСОРНАЯ АЛАЛИЯ – практически на 100% 
              </Typography>
              <Typography  >
              ▪️МОТОРНАЯ АЛАЛИЯ – практически на 100% 
              </Typography>
              <Typography>
              ▪️ЛЮБЫЕ ЗАДЕРЖКИ РЕЧЕВОГО РАЗВИТИЯ (ОНР) – практически на 100% 
              </Typography>
              <Typography>
              ▪️Аутизм и расстройства аутичного спектра (аутичный синдром, частично синдром Каннера и Аспергера) 
              </Typography>
              <Typography>
              ▪️ЗПР – т.е. любые проблемы с интеллектуальной недостаточностью, даже если Вы не знаете ее природы 
              </Typography>
              <Typography>
              ▪️СДВГ – гиперактивность, дефицит внимания.
              </Typography>
              <Typography>
              ▪️Нарушение социализации – ребенок не реагирует на других детей, не играет в обычные игры.
              </Typography>
              <Typography>
              ▪️Нарушения грубой и тонкой моторик
              </Typography>
            </Grid>
          </Container>
        </div>
      <div>
          <Container className={classes.TypographyMainTwo}>
            <Grid>
              <Typography align="center" variant="h6" position="avsolute">
              Инвалидам и семьям, имеющим детей-инвалидов, предоставляется скидка 10%
              </Typography>
              <Typography align="center" variant="h6" position="avsolute">
              А так же многодетным семьям 5%
              </Typography>
            </Grid>
          </Container>
      </div>
        
      </div>
 
    </main>
    </>
	);  
}

export default App;
